import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";

export interface FBuser {
    id: string;
    email: string;
    token: string;
}

const firebaseConfig = {
    apiKey: "AIzaSyB8YInK-pqFICh1C8nX2uDABedNJbZVJVg",
    authDomain: "minersme-mx.firebaseapp.com",
    projectId: "minersme-mx",
    storageBucket: "minersme-mx.appspot.com",
    messagingSenderId: "1037986008773",
    appId: "1:1037986008773:web:e6dbeca003aa6cd153eaac",
    measurementId: "G-E8XED3FM3Z"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
export function logOut(success: () => void) {
    signOut(auth).then(() => {
        // Sign-out successful.
        console.log("Signed out successfully")
    }).catch((e: any) => {
        // An error happened.
    });
}

export function signedUser(callback: (user: FBuser | null) => void) {
    refreshSignedUser(callback, false);
}

export function refreshSignedUser(callback: (user: FBuser | null) => void, refresh: boolean) {
    auth.currentUser?.getIdToken(refresh).then(tokenFound => {
        callback({ id: auth.currentUser?.uid ?? "", email: auth.currentUser?.email ?? "", token: tokenFound })
    }).catch((err) => { callback(null) })
}
