import { Alert, Box, Button, Container, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from "../Helpers/Auth";
import { useNavigate } from 'react-router-dom'
import { DEFAULT_LANDING_PAGE } from "../App";


function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [logingIn, setLogingIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLogingIn(true)
    // Handle login logic here
    signInWithEmailAndPassword(auth, username, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate(DEFAULT_LANDING_PAGE)
        setLogingIn(false)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // console.log(errorCode, errorMessage)
        setLogingIn(false)
        setErrorMessage("Invalid credentials...")
      });
  };
  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '50px' }}>
        <Typography component="h1" variant="h5">
          Sign in - minersme
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={logingIn}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Paper>
      <Snackbar
        open={errorMessage != null}
        autoHideDuration={3000}
        onClose={() => { setErrorMessage(null) }}
      ><Alert severity="error">{errorMessage}</Alert></Snackbar>
    </Container>
  )
}

export default Login;